import React,{Component} from 'react'
import Fab from '@material-ui/core/Fab';
import FilterIcon from '@material-ui/icons/FilterList';
import { createMuiTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import yellow from '@material-ui/core/colors/yellow';
import Modal from './Modal';

const theme = createMuiTheme({
  palette: {
    primary: yellow,
  },
});

export default class LayoutComponent extends Component{
	constructor(props){
		super(props)
		this.state = {
			open: false,
			bairros: JSON.parse(this.props.bairros)
		}
	}
	setOpen(open){
		this.setState({open: open})
	}

	handleClickOpen() {
	    this.setOpen(true);
	}

	handleClose() {
	    this.setOpen(false);
	}
	componentDidMount(){
		
	}

	render(){
		return <div 
		className={"botao-bairros"}
		style={{
			position: 'fixed',
			right: 10,
			bottom: 72,
			zIndex: 1111
		}}>
			{this.state.bairros.length > 0 && <div>
				<ThemeProvider theme={theme}>
			      <Fab color="primary" onClick={()=> this.handleClickOpen()} variant="extended" aria-label="Delete" >
			        <FilterIcon  />
			        Bairros
			      </Fab>
			    </ThemeProvider>
		    </div>}
	      <Modal open={this.state.open} handleClose={()=> this.handleClose()} handleClickOpen={()=> this.handleClickOpen()} bairros={this.state.bairros}/>
	     </div>
	}
}